import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Row, Button, PopoverBody } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import DateRangePickerPrimary from "../../../share-components/ui/DateRangePickerPrimary";
import { DateRangePicker } from "rsuite";
import Breadcrumb from "../../../layout/breadcrumb";
import withReducer from "../../../store/withReducer";
import reportReducer from "../index";
import Select from "react-select";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { mapSearchAutoSuggestion } from "../../../utils/mapper";
import { sellerAutoSuggestionApi, sellerBrandSuggestionApi } from "../../../services/manage-product/productService";
import {
  getHandlingReport
} from "./store/handlingReportSlice";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import { mapInventoryReportResponseToTable } from "../../../utils/mapper";
import PopoverFormula from './content/PopoverFormula';
import {
  formatterDecimal
} from "../../../utils/common";

import "./styles.scss";

const { afterToday } = DateRangePicker;

const HandlingReport = (props) => {

  const endDateObj = new Date(new Date().getFullYear() - 1, 11, 31); // Last year's Dec 31
  const startDateObj = new Date(new Date().getFullYear() - 1, 0, 1); // Last year's Jan 1

  const [dateRange, setDateRange] = useState([startDateObj, endDateObj]);
  const [startDate, endDate] = dateRange;

  const authData = useSelector(({ authReducer }) => authReducer);

  const [searchStatus, setSearchStatus] = useState({ id: 1, name: "Main Products (Default)" });

  const [timer, setTimer] = useState(null);
  const [lastFillters, setLastFillters] = useState(null);
  const [fillterSeller, setFillterSeller] = useState(authData && authData.defaultSeller ? authData.defaultSeller : (authData && authData.defaultSellerOption ? authData.defaultSellerOption : ""));
  const [fillterBrand, setFillterBrand] = useState([{ value: 16, label: "Goddiva" }]);
  const brandIds = fillterBrand ? fillterBrand.map((brand) => brand.value) : null;
  const [filter, setFilter] = useState({
    page: 1,
    pageSize: 10,
    start_date: moment(startDate).format("YYYY-MM-DD"),
    end_date: moment(endDate).format("YYYY-MM-DD"),
    sku: "",
    seller: fillterSeller ? fillterSeller.value : null,
    brand: brandIds,
    // status: searchStatus?.id,
  });

  const dispatch = useDispatch();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const currentUser = useSelector(({ authReducer }) => authReducer.data.user);
  const [loading, setLoading] = useState(true);
  const handlingReportState = useSelector(({ report }) => report.handlingReport);
  const [inventoryDatas, setInventoryDatas] = useState([]);
  const [exportBtnDisabled, setExportBtnDisabled] = useState(false);

  const statuses = [
    { id: 0, name: "All Products" },
    { id: 1, name: "Main Products (Default)" },
    { id: 2, name: "Only Group Products With Child" },
    { id: 3, name: "Only Group Products Without Child" },
  ];

  const dataMenu = [
    {
      type: 0,
      text: "Handling Change Items",
    },
  ];

  const getHandlingReportData = () => {
    if (JSON.stringify(filter) == JSON.stringify(lastFillters)) return;

    setLastFillters({ ...filter });
    setLoading(true);

    dispatch(
      getHandlingReport(filter)
    ).then((res) => {
      if (!res.error) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getHandlingReportData();
  }, [dispatch, filter]);

  useEffect(() => {
    const brandIds = fillterBrand ? fillterBrand.map((brand) => brand.value) : null;
    setFilter(prev => ({
      ...prev,
      page: 1,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      seller: fillterSeller ? fillterSeller.value : null,
      brand: brandIds,
    }));
  }, [startDate, endDate, fillterSeller, fillterBrand])


  const afterToday = () => {
    return (date) => date.getTime() > new Date().getTime();
  };

  const onPageSizeChange = (newPageSize) => {
    setFilter(prev => ({
      ...prev,
      page: 1,
      pageSize: newPageSize === 'All' ? handlingReportState.tableRow : newPageSize
    }));
  }

  const onPageChange = (e) => {
    setFilter({ ...filter, page: e });
  }

  const handleSort = (column, sortDirection) => {
    const sortBy = column.sortField || column.selector; // Use `sortField` if available
    setFilter(prev => ({
      ...prev,
      sortBy, // Get the column field name
      sortOrder: sortDirection, // 'asc' or 'desc'
    }));
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 50,
      border: "none",
    }),
  };

  const exportFile = () => {
    if (exportBtnDisabled) return;
    setExportBtnDisabled(true);

    // dispatch(
    //   exportInventoryHistory({
    //     date: moment(selectedDate).format("YYYY-MM-DD"),
    //     sku: sku ? sku : null,
    //     brand: fillterBrand?.value,
    //     seller: fillterSeller?.value,
    //     status: searchStatus?.id,
    //   })
    // ).then((res) => {
    //   const link = document.createElement("a");
    //   link.href = res.payload;
    //   link.setAttribute("download", "");
    //   document.body.appendChild(link);
    //   link.click();
    //   link.remove();
    //   setExportBtnDisabled(false);
    // });
  };

  const tableColumns = [
    // {
    //   name: "Product Title",
    //   selector: (row) => row.title,
    //   left: true,
    //   grow: 1.2, // column size
    // },
    {
      name: "SKU",
      selector: (row) => row.sku,
      sortable: true,
      sortField: 'sku', // Custom backend field name
      left: true,
      grow: 1, // column size
    },
    // {
    //   name: "Seller",
    //   selector: (row) => row.seller,
    //   sortable: false,
    //   center: false,
    // },
    // {
    //   name: "Brand",
    //   selector: (row) => row.brand,
    //   sortable: false,
    //   center: false,
    // },
    {
      name: "Beginning Qty",
      selector: (row) => row.beginning_quantity,
      sortable: true,
      sortField: 'beginning_quantity', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
    {
      name: "Added Qty",
      selector: (row) => row.added_quantity,
      sortable: true,
      sortField: 'added_quantity', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
    {
      name: "Ending Qty",
      selector: (row) => row.end_quantity,
      sortable: true,
      sortField: 'end_quantity', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
    {
      name: "Processed Qty",
      selector: (row) => row.processed_quantity,
      sortable: true,
      sortField: 'processed_quantity', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
    {
      name: "Ordered Qty",
      selector: (row) => row.ordered_quantity,
      sortable: true,
      sortField: 'ordered_quantity', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
    {
      name: "Restocked Qty",
      selector: (row) => row.restocked_quantity,
      sortable: true,
      sortField: 'restocked_quantity', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
    {
      name: "Total Handling Actions",
      selector: (row) => row.total_handling_actions,
      sortable: true,
      sortField: 'total_handling_actions', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
    {
      name: "Handling Changes per Item",
      selector: (row) => formatterDecimal.format(row.handling_changes_per_item),
      sortable: true,
      sortField: 'handling_changes_per_item', // Custom backend field name
      right: true,
      grow: 0.3, // column size
    },
  ];

  const sellerPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)));
      }, 300);
      setTimer(newTimer)
    });

  const brandPromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
        seller_id: filter.seller,
        check_seller_active: true,
      };
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        resolve(mapSearchAutoSuggestion(sellerBrandSuggestionApi(data)));
      }, 300);
      setTimer(newTimer)
    });

  return (
    <Fragment>
      <Breadcrumb title="Handling Change Items" data={dataMenu} />
      <Container fluid={true} className="product-wrapper hangling-item-page">
        <div className="product-grid">
          <div className="feature-products">
            <Card>
              <Row style={{ justifyContent: "space-between" }}>
                <Col md="4">
                  <div className="p-3">
                    <DateRangePickerPrimary
                      dateRange={dateRange}
                      dateRangeChange={(date) => {
                        setDateRange(date);
                      }}
                      defaultValue={[endDateObj, startDateObj]}
                      disabledDate={afterToday()}
                    />
                  </div>
                </Col>
                {/* <Col md="2">
                  <div className="float-right p-3">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        exportFile();
                      }}
                      disabled={exportBtnDisabled}
                    >
                      <i className={exportBtnDisabled ? "fa fa-spinner fa-spin mr-1" : "fa fa-download mr-1"}></i>
                      Export Report
                    </Button>
                  </div>
                </Col> */}
              </Row>
            </Card>
            <Row>
              {currentUser.role.id != 5 && (
                <Col md="">
                  <AsyncSelect
                    className="select2-filter"
                    isClearable={true}
                    defaultOptions
                    value={fillterSeller}
                    placeholder="Select Seller"
                    loadOptions={sellerPromiseOption}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setFillterSeller(e);
                    }}
                  />
                </Col>
              )}

              <Col md="">
                <AsyncSelect
                  cacheOptions={false}
                  key={filter.seller}
                  className="select2-filter"
                  isClearable={true}
                  isMulti={true}
                  defaultOptions
                  placeholder="Select Brand"
                  loadOptions={brandPromiseOption}
                  value={fillterBrand}
                  styles={customStyles}
                  // menuPortalTarget={document.body}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFillterBrand(e);
                  }}
                />
              </Col>

              <Col md="">
                <Form>
                  <FormGroup className="m-0">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Search SKU"
                      onChange={(e) => {
                        setFilter({ ...filter, page: 1, sku: e.target.value });
                      }}
                    />
                    <i className="fa fa-search"></i>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row></Row>

            <Row>
              <Col sm="12">
                <Card>
                  {loading && (
                      <div className="card-loading">
                          <i className="fa fa-spinner fa-lg spinner" />
                      </div>
                  )}
                  <CardBody>
                    <Row>
                      <Col className="mb-3">
                        <span><strong>Beginning Qty:</strong> <span>{handlingReportState.totalSummary.total_beginning_quantity}</span></span>
                      </Col>
                      <Col className="mb-3">
                        <span><strong>Added Qty:</strong> <span>{handlingReportState.totalSummary.total_added_quantity}</span></span>
                      </Col>
                      <Col className="mb-3">
                        <span><strong>Ending Qty:</strong> <span>{handlingReportState.totalSummary.total_end_quantity}</span></span>
                      </Col>
                      <Col className="mb-3">
                        <span><strong>Processed Qty:</strong> <span>{handlingReportState.totalSummary.total_processed_quantity}</span></span>
                        
                        <PopoverFormula className="ml-3" id="processedQtyFormula">
                            <PopoverBody>
                                <h6>Processed Qty Formula</h6>
                                <p>Processed Qty = Beginning Qty + Added Qty - Ending Qty</p>
                            </PopoverBody>
                        </PopoverFormula>
                      </Col>
                      <Col className="mb-3">
                        <span><strong>Ordered Qty:</strong> <span>{handlingReportState.totalSummary.total_ordered_quantity}</span></span>
                      </Col>
                      <Col className="mb-3">
                        <span><strong>Restocked Qty:</strong> <span>{handlingReportState.totalSummary.total_restocked_quantity}</span></span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <span className="mb-3"><strong>Total Handling Actions:</strong> <span>{handlingReportState.totalSummary.total_handling_actions}</span></span>
                        
                        <PopoverFormula className="ml-3" id="totalHandlingActionsFormula">
                            <PopoverBody>
                                <h6>Total Handling Actions Formula</h6>
                                <p>Total Handling Actions = Ordered Qty + Restocked Qty</p>
                            </PopoverBody>
                        </PopoverFormula>
                      </Col>
                      <Col md="3">
                        <span className="mb-3"><strong>Handling Changes per Items:</strong> <span>{handlingReportState.totalSummary.total_handling_changes_per_item}</span></span>
                        
                        <PopoverFormula className="ml-3" id="handlingChangesItemsFormula">
                            <PopoverBody>
                                <h6>Handling Changes per Items Formula</h6>
                                <p>Handling Changes per Items = Total Handling Actions / Processed Qty</p>
                            </PopoverBody>
                        </PopoverFormula>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="table-responsive product-table">
                      <CommonDataTable
                        headerColumns={tableColumns}
                        gridData={handlingReportState.items}
                        noAction
                        progressPending={loading}
                        paginationServer
                        paginationTotalRows={handlingReportState.tableRow}
                        paginationRowsPerPageOptions={[10, 50, 100, 500, 1000]}
                        paginationDefaultPage={filter.page}
                        paginationPerPage={filter.pageSize}
                        onChangePage={onPageChange}
                        onChangeRowsPerPage={onPageSizeChange}
                        onSort={handleSort}  // Sorting event handler
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default withReducer([{ report: reportReducer }])(HandlingReport);
