import API from "../utils/api";

export const getScanReturnApi = async (data)=>{
    const response= await API.get('retrun/scan-return', {params:data});
    return {response}
};

export const approveScanReturnApi = async (data)=>{
    const response = await API.post('retrun/scan-return/approve', data);
    return {response}
};

export const declineScanReturnApi = async (data)=>{
    const response = await API.post('retrun/scan-return/decline', data);
    return {response}
};