import React from 'react';
import {
    formatter, formatterDecimal
} from "../../../../utils/common";

const Widget = ({ textColorClass, value, label }) => {
    textColorClass = textColorClass ? textColorClass : 'text-dark';
    return (
        <div className="col-md-6 col-xl">
            <div className={`widget-rounded-circle card-box`}>
                <div className="text-right">
                    <h5 className={`mt-1 ${textColorClass}`}>
                        <span>{value}</span>
                    </h5>
                    <p className="text-muted mb-1 text-truncate">{label}</p>
                </div>
            </div>
        </div>
    );
};

const CommissionsTotalSummary = ({ financesSummaryState }) => {
    const totalRow = financesSummaryState.commissionsDetailsTableRow;
    const totalSummary = financesSummaryState.commissionsDetailsTotalSummary;

    const widgetsData = [
        {
            value: totalRow,
            label: "Total Records",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_gross_sale : 0),
            label: "Total Gross Sales",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_discount : 0),
            label: "Total Discounts",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_return : 0),
            label: "Total Returns",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_net_sale : 0),
            label: "Total Net Sales",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_commission_amount : 0),
            label: "Total Commissions",
        },
    ];

    return (
        <div className="row">
            {widgetsData.map((widget, index) => (
                <Widget
                    key={index}
                    textColorClass={widget.textColorClass}
                    value={widget.value}
                    label={widget.label}
                />
            ))}
        </div>
    );
};

export default CommissionsTotalSummary;
