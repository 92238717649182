import React, {Fragment} from "react";

import {Card, CardBody, Col, FormGroup, Label} from "reactstrap";

const Tiktok = (props) => {
    const {errors, register} = props;

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-info ribbon-left mb-5">Tiktok</div>
                    <div className="form-row mb-3">
                        <Col md="12">
                            <FormGroup>
                                <Label className="col-form-label">Tiktok URL</Label>
                                <input name="tiktok_url" type="url" {...register('tiktok_url')}
                                    maxLength={2048}
                                       className={`form-control ${errors.tiktok_url ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.tiktok_url?.message}</div>
                            </FormGroup>
                        </Col>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
}

export default Tiktok;