import React, { useRef, useState } from "react";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";

import { useDispatch } from "react-redux";
import { getScanReturn } from "../../store/returnOrderSlice";
import orderReducer from "../../store";
import withReducer from "../../../../store/withReducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";


const defaultValues = {
    search_num: '',
};

export const schema = yup.object().shape({
    search_num: yup.string().required('Search input is required field.').typeError('Search input is required field.'),
});

const ScanReturnSearchFrom = ({ fillterInputRef, buttonDisabled, setButtonDisabled, playAlertSound }) => {

    const dispatch = useDispatch();
    const [fetchOrderButtonDisabled, setFetchOrderButtonDisabled] = useState(false);

    const { handleSubmit, formState, reset, register, trigger, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { errors } = formState;

    const onSubmitForm = (data) => {
        setFetchOrderButtonDisabled(true);
        setButtonDisabled(true);
        const finalData = {
            search_num: data.search_num
        }

        dispatch(getScanReturn({ ...finalData })).then(res => {
            if (res.error) {
                playAlertSound();
            }else if (res.payload.status.code == 422) {
                toast.error(res.payload.status.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                playAlertSound();
            }
            fillterInputRef.current.focus();
            reset(defaultValues);
            setFetchOrderButtonDisabled(false);
            setButtonDisabled(false);
        });
    }

    const handleChange = (event) => {
        setValue('search_num', event.target.value);
        trigger('search_num')
    };

    return (
        <Card>
            <CardBody>
                <Form onSubmit={handleSubmit(onSubmitForm)}>
                    <Row>
                        <Col md="" sm={4} xs={12}>
                            {/*<Label className="form-label d-none d-md-block">Order Number / Invoice ID</Label>*/}
                        </Col>
                        <Col md={6} sm={4} xs={12} className="mb-3 mb-sm-0">
                            <FormGroup className="m-checkbox-inline mb-0">
                                <input
                                    ref={(ref) => {
                                        fillterInputRef.current = ref;
                                    }}
                                    name="search_num"
                                    type="text"
                                    value={getValues('search_num')}
                                    autoFocus
                                    className={`form-control ${errors.search_num ? 'is-invalid' : ''}`}
                                    placeholder="Order ID/ Marketplace Order ID / Return Tracking ID"
                                    onChange={handleChange}
                                />
                                <div className="invalid-feedback">{errors.search_num?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md={2} sm={4} xs={12}>
                            <button
                                type="submit"
                                className="btn btn-success btn-block btn-lg"
                                disabled={!formState.isValid || fetchOrderButtonDisabled || buttonDisabled}
                            >
                                {fetchOrderButtonDisabled && <i className="fa fa-spinner spinner mr-1" />}
                                Fetch Order
                            </button>
                        </Col>
                        <Col md="" xs={12}></Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    );
}

export default withReducer([{ order: orderReducer }])(ScanReturnSearchFrom);