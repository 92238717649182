import API from "../utils/api";


export const getSellerReportDataApi = async (data) => {
  const response = await API.get('report/sales-report',{
      params: data,
  });
  return {response};
};

export const getBackInStockReportApi = async (data) => {
    const response = await API.get('report/back-in-stock-report',
        {
            params: data,
        });
    return {response};
}

export const getSelectedBackInRequestData = async (id) => {
    const response = await API.get(`report/get-selected-back-in-stock/${id}`);
    return {response};
};

export const getExchangeOrderReportApi = async (data) => {
    const response = await API.get(`order/get-all-exchange-orders`, {params:data});
    return {response};
}

export const offerItemReportApi = async (data) => {
    const response = await API.get('report/offer-report', {params:data});
    return {response};
}

export const getInventoryReportApi = async (data) => {
    const response = await API.get(`report/inventories-report`,{
        params: data,
    });
    return { response };
};

export const getHandlingReportApi = async (data) => {
    const response = await API.get(`report/handling-items`,{
        params: data,
    });
    return { response };
};

export const importStockLevelApi = async (data) => {
    const response = await API.post('import-stock-level', data, {'content-type': 'multipart/form-data'});
    return {response};
}

export const exportInventoryHistoryApi = async (data) => {
    const response = await API.get(`report/inventories-report/export`, {
        params: data,
    });
    return { response };
};

export const sellerBrandSuggestionApi = async (text) => {
    const response = await API.get('product/brand-auto-suggestion', {
        params: text,
    });
    return { response };
};

export const getFinancesSummarySettingApi = async (data) => {
    const response = await API.get('report/finances-summary/settings', {
        params: data,
    });
    return { response };
};

export const getFinancesSummaryApi = async (data) => {
    const response = await API.get('report/finances-summary', {
        params: data,
    });
    return { response };
};

export const exportFinancesSummaryApi = async (data) => {
    const response = await API.get('report/finances-summary/export', {
        params: data,
    });
    return { response };
};

export const getFinancesSalesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-gross-sales', {
        params: data,
    });
    return { response };
};

export const exportFinancesSalesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-gross-sales/export', {
        params: data,
    });
    return { response };
};

export const getFinancesDiscountsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-discounts', {
        params: data,
    });
    return { response };
};

export const exportFinancesDiscountsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-discounts/export', {
        params: data,
    });
    return { response };
};

export const getFinancesReturnsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-returns', {
        params: data,
    });
    return { response };
};

export const exportFinancesReturnsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-returns/export', {
        params: data,
    });
    return { response };
};

export const getFinancesProductCostDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-product-costs', {
        params: data,
    });
    return { response };
};

export const exportFinancesProductCostDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-product-costs/export', {
        params: data,
    });
    return { response };
};

export const getFinancesShippingIncomeDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-shipping-income', {
        params: data,
    });
    return { response };
};

export const exportFinancesShippingIncomeDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-shipping-income/export', {
        params: data,
    });
    return { response };
};

export const getFinancesReturnShippingIncomeDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-return-shipping-income', {
        params: data,
    });
    return { response };
};

export const exportFinancesReturnShippingIncomeDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-return-shipping-income/export', {
        params: data,
    });
    return { response };
};

export const getFinancesShippingCostSaleDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-shipping-cost-sales', {
        params: data,
    });
    return { response };
};

export const exportFinancesShippingCostSaleDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-shipping-cost-sales/export', {
        params: data,
    });
    return { response };
};

export const getFinancesShippingCostReturnDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-shipping-cost-return', {
        params: data,
    });
    return { response };
};

export const exportFinancesShippingCostReturnDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-shipping-cost-return/export', {
        params: data,
    });
    return { response };
};

export const getFinancesCommissionsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-commissions', {
        params: data,
    });
    return { response };
};

export const exportFinancesCommissionsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-commissions/export', {
        params: data,
    });
    return { response };
};

export const getFinancesAdditionalChargesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-additional-charges', {
        params: data,
    });
    return { response };
};

export const exportFinancesAdditionalChargesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-additional-charges/export', {
        params: data,
    });
    return { response };
};

export const getFinancesAgentCommissionDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-agent-commissions', {
        params: data,
    });
    return { response };
};

export const exportFinancesAgentCommissionDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-agent-commissions/export', {
        params: data,
    });
    return { response };
};

export const getFinancesTaxesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-taxes', {
        params: data,
    });
    return { response };
};

export const exportFinancesTaxesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-taxes/export', {
        params: data,
    });
    return { response };
};

export const getFinancesNetSalesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-net-sales', {
        params: data,
    });
    return { response };
};

export const exportFinancesNetSalesDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-net-sales/export', {
        params: data,
    });
    return { response };
};

export const getFinancesPaymentsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-payments', {
        params: data,
    });
    return { response };
};

export const exportFinancesPaymentsDetailsApi = async (data) => {
    const response = await API.get('report/finances-summary/details-payments/export', {
        params: data,
    });
    return { response };
};

export const getFinancesPaymentsApi = async (data) => {
    const response = await API.get('report/finances-summary/payments', {
        params: data,
    });
    return { response };
};

export const getFinancesDeliveryPartnersApi = async (data) => {
    const response = await API.get('report/finances-summary/delivery-partners', {
        params: data,
    });
    return { response };
};

