import React from 'react';
import { DatePicker } from 'rsuite';

export default function MonthPickerComponent(props) {
  const { defaultValue, disabledDate, placement, dateChange } = props;

  return (
    <DatePicker
      size="lg"
      placeholder="Select Month"
      className="form-control hide-border-control"
      format='yyyy-MM'
      oneTap
      placement={placement}
      disabledDate={disabledDate}
      defaultValue={defaultValue}
      // value={new Date(date)}
      onChange={(value) => {
        dateChange(value);
      }}
      cleanable={false}
    />
  );
}
