import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
  const {data, state='update'}=props;
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6" md={8}>
              <h3>{props.title}</h3>
            </Col>
            <Col md="4" xs={6} className="float-sm-end">
              <Breadcrumb>
                <BreadcrumbItem><Link to={`${process.env.PUBLIC_URL}/dashboard`}><Home /></Link></BreadcrumbItem>
                {data?(data.map((item,index)=>{
                  document.title="Marketplace - "+item.text;
                  return item.type===0?
                      (<BreadcrumbItem key={index} active>{item.text}</BreadcrumbItem>)
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      : ( <BreadcrumbItem key={index}><Link to={{pathname:process.env.PUBLIC_URL+'/'+item.link?item.link:null,state:{from:state}}}>{item.text?item.text:null}</Link></BreadcrumbItem> );
                })):null}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Breadcrumbs;