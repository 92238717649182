import React from 'react';
import { DatePicker } from 'rsuite';

export default function DatePickerComponent(props) {
  const { defaultValue, disabledDate, placement, paddingLeft, date, dateChange } = props;
  const styles = { minHeight: '50px', border: 'none !important', display: 'block' };

  return (
    <div className="dashboard-datepicker">
      <DatePicker
        size="lg"
        style={styles}
        placeholder="Select Date"
        className="form-control hide-border-control"
        format='dd/MM/yyyy'
        placement={placement}
        defaultValue={new Date(date)}
        disabledDate={disabledDate}
        value={new Date(date)}
        onChange={(value) => {
          dateChange(value);
        }}
        cleanable={false}
      />
    </div>
  );
}
