import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ModalFormSubmit from "../../../../share-components/form/modalFormSubmit";
import Breadcrumb from "../../../../layout/breadcrumb";
import classnames from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
    getOrderSettings, storeReturnReason, updateReturnReason, deleteReturnReason, openEditDialog,
    openNewDialog, closeEditDialog
} from "../store/returnReasonSettingSlice";
import withReducer from "../../../../store/withReducer";
import orderSettingReducer from "../store/store";
import CommonDataTable from "../../../../share-components/table/commonDataTable";
import ReturnReasonsSettingFrom from "./returnReasonSettingForm";


const defaultValues = {
    id: '',
    name: '',
    type: 1,
    isRestock: false,
    isSeller: false,
};

const dataMenu = [
    {
        type: 0,
        text: 'Order',
        link: '/order'
    },
    {
        type: 1,
        text: 'Order Setting',
        link: ''
    },
    {
        type: 1,
        text: 'Return Reason',
        link: ''
    }
];

const schema = yup.object().shape({
    name: yup.string().required('This is required field'),
});

const ReturnReasonSetting = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [adminReturnReason, setAdminReturnReason] = useState([]);
    const [customerReturnReason, setCustomerReturnReason] = useState([]);
    const [warehouseReason, setWarehouseReason] = useState([]);
    const [returnReasonType, setRuturnReasonType] = useState('admin');
    const dispatch = useDispatch();
    const { handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const { errors } = formState;
    const closeDialog = () => {
        dispatch(closeEditDialog());
    };
    const [loading, setLoading] = useState(false);

    const onSubmitForm = (dialog, data) => {
        let formData = {
            name: data.name,
            isRestock: data.isRestock,
            type: data.type,
            isSeller: data.isSeller
        }

        if (data.id) {
            formData.id = data.id;
        }

        if (dialog.type === 'new') {
            dispatch(storeReturnReason({ ...formData })).then(res => {
                if (!res.error) {
                    getSettingData();
                    closeDialog(dialog.type);
                }
            });
        }
        else {
            dispatch(updateReturnReason({ ...formData })).then(res => {
                if (!res.error) {
                    getSettingData();
                    closeDialog(dialog.type);
                }
            });
        }
    }

    const onEdit = (row) => {
        dispatch(openEditDialog(row))
    }

    const onDelete = (row) => {
        dispatch(deleteReturnReason(row.id)).then(res => {
            getSettingData();
        });
    }    
    
    const getSettingData = () => {        
        dispatch(getOrderSettings()).then(res => {
            setAdminReturnReason(res.payload.admin);
            setCustomerReturnReason(res.payload.customer);
            setWarehouseReason(res.payload.warehouse);
            setLoading(false);
        })
    }

    useEffect(() => {
        getSettingData();
    }, [dispatch]);

    const adminTableColumns = [
        {
            name: 'Reason',
            selector: row => row.name,
            sortable: false,
            center: false,

        },
        {
            name: 'Is Restock',
            selector: row => row.is_restock,
            sortable: false,
            center: false,

        },
        {
            name: 'Is Seller',
            selector: row => row.is_seller,
            sortable: false,
            center: false,

        },
    ];

    const customerTableColumns = [
        {
            name: 'Reason',
            selector: row => row.name,
            sortable: false,
            center: false,
        },
    ];

    const warehouseTableColumns = [
        {
            name: 'Reason',
            selector: row => row.name,
            sortable: false,
            center: false,
        },
    ];

    return (
        <Fragment>
            <Container fluid className="">
                <Breadcrumb data={dataMenu} title="Return Reason" />
                <div >
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' ? activeTab : '' })} onClick={() => { setActiveTab('1'); setRuturnReasonType('admin'); }}>
                                Admin Reason
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' ? activeTab : '' })} onClick={() => { setActiveTab('2'); setRuturnReasonType('customer'); }}>
                                Customer Reason
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '5' ? activeTab : '' })} onClick={() => { setActiveTab('5'); setRuturnReasonType('warehouse') }}>
                                Warehouse Decline Reason
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {/* Admin Reason */}
                        <TabPane tabId="1">
                            <div>
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <Row>
                                                <Col sm="6">
                                                    <Nav tabs className="border-tab">

                                                    </Nav>
                                                </Col>
                                                <Col sm="6">
                                                    <div className='float-sm-right p-3'>
                                                        <a className="btn btn-sm btn-primary" onClick={() => {

                                                            dispatch(openNewDialog())
                                                        }}
                                                        > <i className="fa fa-plus"></i> Add new reason</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>

                                <CommonDataTable headerColumns={adminTableColumns} gridData={adminReturnReason} onEdit={onEdit} onDelete={onDelete} />
                            </div>
                        </TabPane>

                        {/* Customer Reason */}
                        <TabPane tabId="2">
                            <div>
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <Row>
                                                <Col sm="6">
                                                    <Nav tabs className="border-tab">

                                                    </Nav>
                                                </Col>
                                                <Col sm="6">
                                                    <div className='float-sm-right p-3'>
                                                        <a className="btn btn-sm btn-primary" onClick={() => {

                                                            dispatch(openNewDialog())
                                                        }}
                                                        > <i className="fa fa-plus"></i> Add new reason</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <CommonDataTable headerColumns={customerTableColumns} gridData={customerReturnReason} onEdit={onEdit} onDelete={onDelete} />
                            </div>
                        </TabPane>

                        {/* Warehouse Decline Reason */}
                        <TabPane tabId="5">
                            <Card className="rounded-0">
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm="12">
                                            <div className="float-sm-right">
                                                <a
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => {
                                                        dispatch(openNewDialog())
                                                    }}
                                                ><i className="fa fa-plus"></i> Add new reason</a>
                                            </div>
                                        </Col>
                                    </Row>

                                    <CommonDataTable headerColumns={warehouseTableColumns} gridData={warehouseReason} onEdit={onEdit} onDelete={onDelete} />
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>

                    <ModalFormSubmit
                        defaultValues={defaultValues}
                        schema={schema}
                        formState={formState}
                        handleSubmit={handleSubmit}
                        onSubmitForm={onSubmitForm}
                        noAction
                        reset={reset}
                        title={returnReasonType == 'warehouse' ? 'Warehouse Decline Reason' : 'Return Reason'}
                        selector='orderSetting'
                        dialog={({ returnReasonSetting }) => returnReasonSetting.returnReasonSetting.addDialog}
                        closeDialog={closeDialog}
                        setValue={setValue}
                        getValues={getValues}
                        btnText="Save">
                        <ReturnReasonsSettingFrom
                            returnReasonType={returnReasonType}
                            control={control}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            trigger={trigger}
                            getValues={getValues}
                        />
                    </ModalFormSubmit>
                </div >
            </Container>

        </Fragment>
    );
}


export default withReducer([{ returnReasonSetting: orderSettingReducer }])(ReturnReasonSetting);