import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
    getFinancesSummarySettingApi, 
    getFinancesSummaryApi,
    exportFinancesSummaryApi,
    getFinancesSalesDetailsApi,
    exportFinancesSalesDetailsApi,
    getFinancesDiscountsDetailsApi,
    exportFinancesDiscountsDetailsApi,
    getFinancesReturnsDetailsApi,
    exportFinancesReturnsDetailsApi,
    getFinancesProductCostDetailsApi,
    exportFinancesProductCostDetailsApi,
    getFinancesShippingIncomeDetailsApi,
    exportFinancesShippingIncomeDetailsApi,
    getFinancesReturnShippingIncomeDetailsApi,
    exportFinancesReturnShippingIncomeDetailsApi,
    getFinancesShippingCostSaleDetailsApi,
    exportFinancesShippingCostSaleDetailsApi,
    getFinancesShippingCostReturnDetailsApi,
    exportFinancesShippingCostReturnDetailsApi,
    getFinancesCommissionsDetailsApi,
    exportFinancesCommissionsDetailsApi,
    getFinancesAdditionalChargesDetailsApi,
    exportFinancesAdditionalChargesDetailsApi,
    getFinancesAgentCommissionDetailsApi,
    exportFinancesAgentCommissionDetailsApi,
    getFinancesTaxesDetailsApi,
    exportFinancesTaxesDetailsApi,
    getFinancesNetSalesDetailsApi,
    exportFinancesNetSalesDetailsApi,
    getFinancesPaymentsDetailsApi,
    exportFinancesPaymentsDetailsApi,
    getFinancesPaymentsApi,
    getFinancesDeliveryPartnersApi,
} from "../../../../services/report";


export const getFinancesSummary = createAsyncThunk('report/getFinancesSummary', async (data, { dispatch }) => {
    const response = await getFinancesSummaryApi(data);
    dispatch(setData(response.response));
    return response.response;
});


export const exportFinancesSummary = createAsyncThunk('report/exportFinancesSummary', async (data, { dispatch }) => {
    const response = await exportFinancesSummaryApi(data);
    return response.response;
});


export const getFinancesSummarySetting = createAsyncThunk('report/getFinancesSummarySetting', async (data, { dispatch, }) => {
    const response = await getFinancesSummarySettingApi(data);
    dispatch(setSettings(response.response))
    return response.response;
});


export const getFinancesSalesDetails = createAsyncThunk('report/getFinancesSalesDetails', async (data, { dispatch }) => {
    const response = await getFinancesSalesDetailsApi(data);
    dispatch(setSaleDetailsData(response.response));
    return response.response;
});


export const exportFinancesSalesDetails = createAsyncThunk('report/exportFinancesSalesDetails', async (data, { dispatch }) => {
    const response = await exportFinancesSalesDetailsApi(data);
    return response.response;
});


export const getFinancesDiscountsDetails = createAsyncThunk('report/getFinancesDiscountsDetails', async (data, { dispatch }) => {
    const response = await getFinancesDiscountsDetailsApi(data);
    dispatch(setDiscountDetailsData(response.response));
    return response.response;
});


export const exportFinancesDiscountsDetails = createAsyncThunk('report/exportFinancesDiscountsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesDiscountsDetailsApi(data);
    return response.response;
});


export const getFinancesReturnsDetails = createAsyncThunk('report/getFinancesReturnsDetails', async (data, { dispatch }) => {
    const response = await getFinancesReturnsDetailsApi(data);
    dispatch(setReturnDetailsData(response.response));
    return response.response;
});


export const exportFinancesReturnsDetails = createAsyncThunk('report/exportFinancesReturnsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesReturnsDetailsApi(data);
    return response.response;
});


export const getFinancesProductCostDetails = createAsyncThunk('report/getFinancesProductCostDetails', async (data, { dispatch }) => {
    const response = await getFinancesProductCostDetailsApi(data);
    dispatch(setProductCostDetailsData(response.response));
    return response.response;
});


export const exportFinancesProductCostDetails = createAsyncThunk('report/exportFinancesProductCostDetails', async (data, { dispatch }) => {
    const response = await exportFinancesProductCostDetailsApi(data);
    return response.response;
});


export const getFinancesShippingIncomeDetails = createAsyncThunk('report/getFinancesShippingIncomeDetails', async (data, { dispatch }) => {
    const response = await getFinancesShippingIncomeDetailsApi(data);
    dispatch(setShippingIncomeDetailsData(response.response));
    return response.response;
});


export const exportFinancesShippingIncomeDetails = createAsyncThunk('report/exportFinancesShippingIncomeDetails', async (data, { dispatch }) => {
    const response = await exportFinancesShippingIncomeDetailsApi(data);
    return response.response;
});


export const getFinancesReturnShippingIncomeDetails = createAsyncThunk('report/getFinancesReturnShippingIncomeDetails', async (data, { dispatch }) => {
    const response = await getFinancesReturnShippingIncomeDetailsApi(data);
    dispatch(setReturnShippingIncomeDetailsData(response.response));
    return response.response;
});


export const exportFinancesReturnShippingIncomeDetails = createAsyncThunk('report/exportFinancesReturnShippingIncomeDetails', async (data, { dispatch }) => {
    const response = await exportFinancesReturnShippingIncomeDetailsApi(data);
    return response.response;
});


export const getFinancesShippingCostSaleDetails = createAsyncThunk('report/getFinancesShippingCostSaleDetails', async (data, { dispatch }) => {
    const response = await getFinancesShippingCostSaleDetailsApi(data);
    dispatch(setShippingCostSaleDetailsData(response.response));
    return response.response;
});


export const exportFinancesShippingCostSaleDetails = createAsyncThunk('report/exportFinancesShippingCostSaleDetails', async (data, { dispatch }) => {
    const response = await exportFinancesShippingCostSaleDetailsApi(data);
    return response.response;
});


export const getFinancesShippingCostReturnDetails = createAsyncThunk('report/getFinancesShippingCostReturnDetails', async (data, { dispatch }) => {
    const response = await getFinancesShippingCostReturnDetailsApi(data);
    dispatch(setShippingCostReturnDetailsData(response.response));
    return response.response;
});


export const exportFinancesShippingCostReturnDetails = createAsyncThunk('report/exportFinancesShippingCostReturnDetails', async (data, { dispatch }) => {
    const response = await exportFinancesShippingCostReturnDetailsApi(data);
    return response.response;
});


export const getFinancesCommissionsDetails = createAsyncThunk('report/getFinancesCommissionsDetails', async (data, { dispatch }) => {
    const response = await getFinancesCommissionsDetailsApi(data);
    dispatch(setCommissionsDetailsData(response.response));
    return response.response;
});


export const exportFinancesCommissionsDetails = createAsyncThunk('report/exportFinancesCommissionsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesCommissionsDetailsApi(data);
    return response.response;
});


export const getFinancesAdditionalChargesDetails = createAsyncThunk('report/getFinancesAdditionalChargesDetails', async (data, { dispatch }) => {
    const response = await getFinancesAdditionalChargesDetailsApi(data);
    dispatch(setAdditionalChargesDetailsData(response.response));
    return response.response;
});


export const exportFinancesAdditionalChargesDetails = createAsyncThunk('report/exportFinancesAdditionalChargesDetails', async (data, { dispatch }) => {
    const response = await exportFinancesAdditionalChargesDetailsApi(data);
    return response.response;
});


export const getFinancesAgentCommissionDetails = createAsyncThunk('report/getFinancesAgentCommissionDetails', async (data, { dispatch }) => {
    const response = await getFinancesAgentCommissionDetailsApi(data);
    dispatch(setAgentCommissionDetailsData(response.response));
    return response.response;
});


export const exportFinancesAgentCommissionDetails = createAsyncThunk('report/exportFinancesAgentCommissionDetails', async (data, { dispatch }) => {
    const response = await exportFinancesAgentCommissionDetailsApi(data);
    return response.response;
});


export const getFinancesTaxesDetails = createAsyncThunk('report/getFinancesTaxesDetails', async (data, { dispatch }) => {
    const response = await getFinancesTaxesDetailsApi(data);
    dispatch(setTaxesDetailsData(response.response));
    return response.response;
});


export const exportFinancesTaxesDetails = createAsyncThunk('report/exportFinancesTaxesDetails', async (data, { dispatch }) => {
    const response = await exportFinancesTaxesDetailsApi(data);
    return response.response;
});


export const getFinancesNetSalesDetails = createAsyncThunk('report/getFinancesNetSalesDetails', async (data, { dispatch }) => {
    const response = await getFinancesNetSalesDetailsApi(data);
    dispatch(setNetSalesDetailsData(response.response));
    return response.response;
});


export const exportFinancesNetSalesDetails = createAsyncThunk('report/exportFinancesNetSalesDetails', async (data, { dispatch }) => {
    const response = await exportFinancesNetSalesDetailsApi(data);
    return response.response;
});


export const getFinancesPaymentsDetails = createAsyncThunk('report/getFinancesPaymentsDetails', async (data, { dispatch }) => {
    const response = await getFinancesPaymentsDetailsApi(data);
    dispatch(setPaymentDetailsData(response.response));
    return response.response;
});


export const exportFinancesPaymentsDetails = createAsyncThunk('report/exportFinancesPaymentsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesPaymentsDetailsApi(data);
    return response.response;
});


export const getFinancesPayments = createAsyncThunk('report/getFinancesPayments', async (data, { dispatch }) => {
    const response = await getFinancesPaymentsApi(data);
    dispatch(setPaymentsData(response.response));
    return response.response;
});


export const getFinancesDeliveryPartners = createAsyncThunk('report/getFinancesDeliveryPartners', async (data, { dispatch }) => {
    const response = await getFinancesDeliveryPartnersApi(data);
    dispatch(setDeliveryPartnersData(response.response));
    return response.response;
});


const financesSummaryAdapter = createEntityAdapter({});

export const { selectAll: selectFinancesSummary, selectById: selectFinancesSummaryById } = financesSummaryAdapter.getSelectors(
    state => state.report
);

const financesSummarySlice = createSlice({
    name: 'financesSummary',
    initialState: financesSummaryAdapter.getInitialState({
        id: null,
        settings: {
            partners: [],
            deliveryPartners: [],
            shippingTypes: [],
            currencies: [],
        },
        filters: {
            startDate: null,
            endDate: null,
            partner: null
        },
        data: {
            gross_sales: 0,
            discount: 0,
            shipping_charges: 0,
            shipping_rate: 0,
            shipping_rate_return: 0,
            total_tax: 0,
            total_return: 0,
            net_sales: 0,
            total_sales: 0,
            total_mp_cost: 0,
            gross_profit: 0,
            final_profit: 0,
            commission_percentage: 0,
            commission_amount: 0,
            additional_commission_percentage: 0,
            additional_commission_amount: 0,
            agent_commission_percentage: 0,
            agent_commission_amount: 0,
            debenham_difference_gross_sales: 0,
            difference_gross_sales: 0,
            debenham_difference_discount: 0,
            difference_discount: 0,
            difference_total_return: 0,
            difference_vat_round_gross_sales: 0,
            taxItems: [],
            partnerItems: [],
            paymentsSource: [],
            countryItems: {}
        },
        test_order_summary: null,
        deliveryPartnersData: [],
        returnDeliveryPartnersData: [],
        paymentLists: [],
        saleDetailsLists: [],
        saleDetailsTableRow: 0,
        saleDetailsTableSummary: null,
        discountDetailsLists: [],
        discountDetailsTableRow: 0,
        discountDetailsTableSummary: null,
        returnDetailsLists: [],
        returnDetailsTableRow: 0,
        returnDetailsTotalSummary: null,
        productCostDetailsLists: [],
        productCostDetailsTableRow: 0,
        productCostDetailsTotalSummary: null,
        shippingIncomeDetailsLists: [],
        shippingIncomeDetailsTableRow: 0,
        shippingIncomeDetailsTotalSummary: null,
        returnShippingIncomeDetailsLists: [],
        returnShippingIncomeDetailsTableRow: 0,
        returnShippingIncomeDetailsTotalSummary: null,
        shippingCostSaleDetailsLists: [],
        shippingCostSaleDetailsTableRow: 0,
        shippingCostSaleDetailsTotalSummary: null,
        shippingCostReturnDetailsLists: [],
        shippingCostReturnDetailsTableRow: 0,
        shippingCostReturnDetailsTotalSummary: null,
        commissionsDetailsLists: [],
        commissionsDetailsTableRow: 0,
        commissionsDetailsTotalSummary: null,
        additionalChargesDetailsLists: [],
        additionalChargesDetailsTableRow: 0,
        additionalChargesDetailsTotalSummary: null,
        agentCommissionDetailsLists: [],
        agentCommissionDetailsTableRow: 0,
        agentCommissionDetailsTotalSummary: null,
        taxesDetailsLists: [],
        taxesDetailsTableRow: 0,
        taxesDetailsTotalSummary: null,
        netSalesDetailsLists: [],
        netSalesDetailsTableRow: 0,
        netSalesDetailsTotalSummary: null,
        paymentsDetailsLists: [],
        paymentsDetailsTableRow: 0,
        paymentsDetailsTotalSummary: null,
    }),
    reducers: {
        setSettings: (state, action) => {
            state.settings.partners = action.payload.partners;
            state.settings.deliveryPartners = action.payload.delivery_partners;
            state.settings.shippingTypes = action.payload.shipping_types;
            state.settings.currencies = action.payload.currencies;
        },
        setFilter: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload.data;
            state.test_order_summary = action.payload.test_order_summary;
        },
        setPaymentsData: (state, action) => {
            state.paymentLists = action.payload;
        },
        setDeliveryPartnersData: (state, action) => {
            state.deliveryPartnersData = action.payload.delivery_partners_data;
            state.returnDeliveryPartnersData = action.payload.return_delivery_partners_data;
        },
        setSaleDetailsData: (state, action) => {
            state.saleDetailsLists = action.payload.data;
            state.saleDetailsTableRow = action.payload.total;
            state.saleDetailsTableSummary = action.payload.total_summary;
        },
        setDiscountDetailsData: (state, action) => {
            state.discountDetailsLists = action.payload.data;
            state.discountDetailsTableRow = action.payload.total;
            state.discountDetailsTableSummary = action.payload.total_summary;
        },
        setReturnDetailsData: (state, action) => {
            state.returnDetailsLists = action.payload.data;
            state.returnDetailsTableRow = action.payload.total;
            state.returnDetailsTotalSummary = action.payload.total_summary;
        },
        setProductCostDetailsData: (state, action) => {
            state.productCostDetailsLists = action.payload.data;
            state.productCostDetailsTableRow = action.payload.total;
            state.productCostDetailsTotalSummary = action.payload.total_summary;
        },
        setShippingIncomeDetailsData: (state, action) => {
            state.shippingIncomeDetailsLists = action.payload.data;
            state.shippingIncomeDetailsTableRow = action.payload.total;
            state.shippingIncomeDetailsTotalSummary = action.payload.total_summary;
        },
        setReturnShippingIncomeDetailsData: (state, action) => {
            state.returnShippingIncomeDetailsLists = action.payload.data;
            state.returnShippingIncomeDetailsTableRow = action.payload.total;
            state.returnShippingIncomeDetailsTotalSummary = action.payload.total_summary;
        },
        setShippingCostSaleDetailsData: (state, action) => {
            state.shippingCostSaleDetailsLists = action.payload.data;
            state.shippingCostSaleDetailsTableRow = action.payload.total;
            state.shippingCostSaleDetailsTotalSummary = action.payload.total_summary;
        },
        setShippingCostReturnDetailsData: (state, action) => {
            state.shippingCostReturnDetailsLists = action.payload.data;
            state.shippingCostReturnDetailsTableRow = action.payload.total;
            state.shippingCostReturnDetailsTotalSummary = action.payload.total_summary;
        },
        setCommissionsDetailsData: (state, action) => {
            state.commissionsDetailsLists = action.payload.data;
            state.commissionsDetailsTableRow = action.payload.total;
            state.commissionsDetailsTotalSummary = action.payload.total_summary;
        },
        setAdditionalChargesDetailsData: (state, action) => {
            state.additionalChargesDetailsLists = action.payload.data;
            state.additionalChargesDetailsTableRow = action.payload.total;
            state.additionalChargesDetailsTotalSummary = action.payload.total_summary;
        },
        setAgentCommissionDetailsData: (state, action) => {
            state.agentCommissionDetailsLists = action.payload.data;
            state.agentCommissionDetailsTableRow = action.payload.total;
            state.agentCommissionDetailsTotalSummary = action.payload.total_summary;
        },
        setTaxesDetailsData: (state, action) => {
            state.taxesDetailsLists = action.payload.data;
            state.taxesDetailsTableRow = action.payload.total;
            state.taxesDetailsTotalSummary = action.payload.total_summary;
        },
        setNetSalesDetailsData: (state, action) => {
            state.netSalesDetailsLists = action.payload.data;
            state.netSalesDetailsTableRow = action.payload.total;
            state.netSalesDetailsTotalSummary = action.payload.total_summary;
        },
        setPaymentDetailsData: (state, action) => {
            state.paymentsDetailsLists = action.payload.data;
            state.paymentsDetailsTableRow = action.payload.total;
            state.paymentsDetailsTotalSummary = action.payload.total_summary;
        },
    },
    extraReducers: {
        // [getFinancesSummary.fulfilled]: financesSummaryAdapter.setAll
    }
});

export const {
    setSettings,
    setFilter,
    setData,
    setPaymentsData,
    setDeliveryPartnersData,
    setSaleDetailsData,
    setDiscountDetailsData,
    setReturnDetailsData,
    setProductCostDetailsData,
    setShippingIncomeDetailsData,
    setReturnShippingIncomeDetailsData,
    setShippingCostSaleDetailsData,
    setShippingCostReturnDetailsData,
    setCommissionsDetailsData,
    setAdditionalChargesDetailsData,
    setAgentCommissionDetailsData,
    setTaxesDetailsData,
    setNetSalesDetailsData,
    setPaymentDetailsData,
} = financesSummarySlice.actions;

export default financesSummarySlice.reducer;