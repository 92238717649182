import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
import { getScanReturnApi, approveScanReturnApi, declineScanReturnApi } from '../../../services/return';

export const getScanReturn = createAsyncThunk('order/getScanReturn', async (data, { dispatch }) => {
    try {
        const response = await getScanReturnApi(data);
        dispatch(setScanReturnData(response.response));
        return response.response;
    } catch (e) {
        dispatch(setScanReturnData(null));
        return Promise.reject(e);
    }
});

export const approveScanReturn = createAsyncThunk('order/approveScanReturn', async (data, { dispatch }) => {
    try {
        const response = await approveScanReturnApi(data);
        dispatch(setScanReturnData(null));
        toast.success(response.response, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });
        return response.response;
    } catch (e) {
        dispatch(setScanReturnData(null));
        return Promise.reject(e);
    }
});

export const declineScanReturn = createAsyncThunk('order/declineScanReturn', async (data, { dispatch }) => {
    try {
        const response = await declineScanReturnApi(data);
        dispatch(setScanReturnData(null));
        toast.success(response.response, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });
        return response.response;
    } catch (e) {
        dispatch(setScanReturnData(null));
        return Promise.reject(e);
    }
});

const returnAdapter = createEntityAdapter({});
export const { selectAll: selectAll, selectById: selectById } = returnAdapter.getSelectors(
    state => state.scanReturnData
);

const returnSlice = createSlice({
    name: 'returnOrder',
    initialState: returnAdapter.getInitialState({
        scanReturnData: null,
    }),
    reducers: {
        setScanReturnData: (state, action) => {
            state.scanReturnData = action.payload;
        },
    },
    extraReducers: {
        [getScanReturn.fulfilled]: returnAdapter.setAll,
    }
});

export const {
    setScanReturnData
} = returnSlice.actions;

export default returnSlice.reducer;