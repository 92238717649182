import React from 'react';
import {
    formatter, formatterCurrency
} from "../../../../utils/common";

const Widget = ({ textColorClass, value, label, isShowSubValue, subValue }) => {
    textColorClass = textColorClass ? textColorClass : 'text-dark';
    return (
        <div className="col-md-6 col-xl">
            <div className={`widget-rounded-circle card-box summary-card-box`}>
                <div className="text-right">
                    <h6 className="text-truncate card-box-title">{label}</h6>
                    <h5 className={`card-box-value ${textColorClass}`}>{value}</h5>

                    {isShowSubValue ? (                    
                    <h6 className="card-box-sub-value">{subValue}</h6>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const ReturnShippingIncomeTotalSummary = ({ financesSummaryState }) => {
    const totalRow = financesSummaryState.returnShippingIncomeDetailsTableRow;
    const totalSummary = financesSummaryState.returnShippingIncomeDetailsTotalSummary;
    const currency = totalSummary && totalSummary.currency ? totalSummary.currency : null;

    const widgetsData = [
        {
            value: totalRow,
            label: "Total Records",
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_shipping_charges : 0),
            label: "Total Gross Shipping",
            isShowSubValue: currency && currency !== 'GBP',
            subValue: formatterCurrency((totalSummary ? totalSummary.pc_total_gross_shipping : 0), currency),
        },
        {
            value: formatter.format(totalSummary ? totalSummary.total_shipping_tax : 0),
            label: "Total Taxes",
            isShowSubValue: currency && currency !== 'GBP',
            subValue: formatterCurrency((totalSummary ? totalSummary.pc_total_tax_shipping : 0), currency),
        },
        {
            textColorClass: 'text-success',
            value: formatter.format(totalSummary ? totalSummary.total_net_shipping : 0),
            label: "Total Net Shipping",
            isShowSubValue: currency && currency !== 'GBP',
            subValue: formatterCurrency((totalSummary ? totalSummary.pc_total_net_shipping : 0), currency),
        },
    ];

    return (
        <div className="row">
            {widgetsData.map((widget, index) => (
                <Widget
                    key={index}
                    textColorClass={widget.textColorClass}
                    value={widget.value}
                    label={widget.label}
                    isShowSubValue={widget.isShowSubValue}
                    subValue={widget.subValue}
                />
            ))}
        </div>
    );
};

export default ReturnShippingIncomeTotalSummary;
